import React from 'react';
import { ApplicantApiClient } from 'providers/ApplicantApiClient';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


const SuccessPage = ({ todoId, onReset, todoItems }) => {
    const finicityTodo = todoItems?.find(item => item.definitionId === "Finicity");
    const plaidTodo = todoItems?.find(item => item.definitionId === "Plaid");
    const statementsTodo = todoItems?.find(item => item.definitionId === "Statements");


    const handleReset = async (todoItemId) => {
        try {

            await ApplicantApiClient.put(`/api/applicant/todoItem/${todoItemId}/reset`);
            if (onReset) onReset();
        } catch (error) {
            console.error('Error resetting todo item:', error);
        }
    };

    const handleClose = () => window.close();

    return (
        <MDBox
            sx={{
                background: 'linear-gradient(195deg, #42424a 0%, #191919 100%)',
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <MDBox
                sx={{
                    background: 'rgba(255, 255, 255, 0.9)',

                    borderRadius: '20px',
                    width: '90%',
       
                    padding: '3rem',

                }}
            >
                {/* Success Icon and Avatar */}
                <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mb={4}
                >
                    <MDBox
                        sx={{
                            background: 'linear-gradient(195deg, #66BB6A 0%, #43A047 100%)',
                            borderRadius: '50%',
                            padding: '1rem',
                            marginBottom: '1.5rem'
                        }}
                    >
                        <CheckCircleIcon sx={{ fontSize: 40, color: 'white' }} />
                    </MDBox>
               
                </MDBox>

                {/* Success Message */}
                <MDBox textAlign="center" mb={4}>
                    <MDTypography
                        variant="h3"
                        sx={{
                            background: 'linear-gradient(195deg, #42424a 0%, #191919 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            marginBottom: '1rem'
                        }}
                    >
                        Thank You!
                    </MDTypography>
                    <MDTypography
                        variant="body1"
                        color="text"
                        sx={{ fontSize: '1.1rem', lineHeight: '1.6' }}
                    >
                        I'll be reaching out soon to help move this process forward.
                        Looking forward to doing business with you!
                    </MDTypography>
                </MDBox>

                {/* Action Buttons */}
                <Stack spacing={2.5}>
   
                        <MDBox>
                            <MDTypography
                                variant="button"
                                color="text"
                                fontWeight="regular"
                                mb={1.5}
                                display="block"
                            >
                                Would you like to connect your bank accounts?
                            </MDTypography>
                            <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => handleReset(finicityTodo?.todoId)}
                                startIcon={<AccountBalanceIcon />}
                                fullWidth
                                sx={{
                                    borderRadius: '10px',
                                    padding: '0.8rem'
                                }}
                            >
                                Link Bank Account
                            </MDButton>
                        </MDBox>

                        <MDBox>
                            <MDTypography
                                variant="button"
                                color="text"
                                fontWeight="regular"
                                mb={1.5}
                                display="block"
                            >
                                Need to upload more statements?
                            </MDTypography>
                            <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => handleReset(statementsTodo?.todoId)}
                                startIcon={<UploadFileIcon />}
                                fullWidth
                                sx={{
                                    borderRadius: '10px',
                                    padding: '0.8rem'
                                }}
                            >
                                Upload More Statements
                            </MDButton>
                        </MDBox>


                    <MDBox mt={2}>
                        <MDButton
                            variant="gradient"
                            color="dark"
                            onClick={handleClose}
                            startIcon={<CloseIcon />}
                            fullWidth
                            sx={{
                                borderRadius: '10px',
                                padding: '0.8rem',
                                background: 'linear-gradient(195deg, #42424a 0%, #191919 100%)'
                            }}
                        >
                            Close Window
                        </MDButton>
                    </MDBox>
                </Stack>
            </MDBox>
        </MDBox>
    );
};

export default SuccessPage;