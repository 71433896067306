import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ApplicantApiClient } from '../providers/ApplicantApiClient';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Alert } from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import MDTypography from "components/MDTypography";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';

const StatementsUploadPage = ({ todoId, onBack, onCompleted }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [dragActive, setDragActive] = useState(false);
    const [todoDetails, setTodoDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchTodoDetails = async () => {
            try {
                const data = await ApplicantApiClient.get('/api/applicant/todoitems');
                const currentTodo = data.find(todo => todo.todoId === todoId);
                if (currentTodo) {
                    setTodoDetails(currentTodo);
                    console.log('Todo Details:', currentTodo);
                }
            } catch (error) {
                console.error('Error fetching todo items:', error);
                setError('Failed to load todo information');
            } finally {
                setIsLoading(false);
            }
        };

        fetchTodoDetails();
    }, [todoId]);

    const handleFileUpload = async (files) => {
        if (!files || files.length === 0) return;

        if (!todoDetails?.applicationId || !todoDetails?.contactId) {
            setError('Application or Contact ID not found. Please try again.');
            console.error('Missing IDs from todo details:', todoDetails);
            return;
        }

        setIsUploading(true);
        setError(null);

        const uploadPromises = Array.from(files).map(async (file) => {
            if (!file.type.includes('pdf')) {
                throw new Error(`${file.name} is not a PDF file`);
            }

            const formData = new FormData();
            formData.append('File', file);

            try {
                const response = await axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_TENANT_API}/api/Documents/UploadPDF/${todoDetails.applicationId}/${todoDetails.contactId}`,
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                return response.data;
            } catch (error) {
                console.error('Upload error:', error.response || error);
                throw new Error(`Failed to upload ${file.name}: ${error.response?.data?.detail || error.message}`);
            }
        });

        try {
            const results = await Promise.all(uploadPromises);
            setUploadedFiles(prev => [...prev, ...results]);
            setIsUploading(false);
        } catch (error) {
            setError(error.message);
            setIsUploading(false);
        }
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            await handleFileUpload(e.dataTransfer.files);
        }
    };

    const handleInputChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            await handleFileUpload(e.target.files);
        }
    };

    const handleComplete = async () => {
        try {
            setIsLoading(true);
            // Mark the todo item as complete
            await ApplicantApiClient.post(`/api/applicant/statement/${todoId}`);
            if (onCompleted) {
                onCompleted();
            }
        } catch (error) {
            console.error('Error completing todo item:', error);
            setError('Failed to complete the upload process');
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </MDBox>
        );
    }

    if (!todoDetails?.applicationId || !todoDetails?.contactId) {
        return (
            <MDBox>
                <Alert severity="error">
                    Application or Contact ID not found. Please refresh the page and try again.
                </Alert>
            </MDBox>
        );
    }

    return (
        <MDBox>
            <MDBox mb={3}>
                <MDTypography variant="h5">Upload Bank Statements</MDTypography>
                <MDTypography variant="body2" color="text">
                    Please upload your bank statements for the past 3 months (PDF format only)
                </MDTypography>
            </MDBox>

            <MDBox
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                sx={{
                    border: '2px dashed',
                    borderColor: dragActive ? 'primary.main' : 'grey.300',
                    borderRadius: 2,
                    p: 3,
                    textAlign: 'center',
                    cursor: 'pointer',
                    bgcolor: dragActive ? 'action.hover' : 'background.paper',
                    transition: 'all 0.2s ease'
                }}
            >
                <input
                    type="file"
                    multiple
                    accept=".pdf"
                    onChange={handleInputChange}
                    style={{ display: 'none' }}
                    id="file-upload"
                />

                <label htmlFor="file-upload" style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                    <Stack spacing={2} alignItems="center">
                        <UploadFileIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
                        <MDTypography variant="body1">
                            Drag and drop PDF files here or click to browse
                        </MDTypography>
                    </Stack>
                </label>
            </MDBox>

            {error && (
                <MDBox mt={2}>
                    <Alert severity="error">{error}</Alert>
                </MDBox>
            )}

            {uploadedFiles.length > 0 && (
                <MDBox mt={2}>
                    <MDTypography variant="h6" mb={1}>Uploaded Files:</MDTypography>
                    {uploadedFiles.map((file, index) => (
                        <MDTypography key={index} variant="body2">
                            ✓ {file.originalFileName}
                        </MDTypography>
                    ))}
                </MDBox>
            )}

            <MDBox mt={3} display="flex" justifyContent="space-between">
                {onBack && (
                    <MDButton variant="outlined" color="secondary" onClick={onBack}>
                        Back
                    </MDButton>
                )}
                <MDButton
                    variant="contained"
                    color="primary"
                    onClick={handleComplete}
                    disabled={isUploading || uploadedFiles.length === 0}
                >
                    {isUploading ? <CircularProgress size={24} /> : 'Continue'}
                </MDButton>
                 
                <MDButton
                    variant="contained"
                    color="primary"
                    onClick={handleComplete}
     
                >
                     Skip
                </MDButton>
            </MDBox>
        </MDBox>
    );
};

export default StatementsUploadPage;